<template>
  <div class="container">
      <div class="private-wrap" v-if="cartList.groupType==='1' && (originAddress.address_id ==='' || originAddress.address_id === undefined || originAddress.self_delivery_id ==='' || originAddress.self_delivery_id === undefined)">
          <span class="private_domain" >此订单为私域拼团，须分享好友才能成团！</span>
          <span class="share-btn" @click="share">邀请</span>
      </div>
    <div class="success-wrap">
      <div class="success-top">
        <div class="sucess-icon">
            <img src="../../images/success.png"/>
        </div>
        <span class="success-txt">支付成功</span>
      </div>
      <div class="success-cell">
        <span>支付方式：</span>
        <span v-if="index == 0">微信支付</span>
        <span v-if="index == 1">农行支付</span>
      </div>
      <div class="success-cell">
        <span>订单金额：</span>
        <span v-if="index == 1 && abcPayDiscount > 0">{{finalPrice}}</span>
        <span v-else>{{allAmount}}</span>
      </div>
    </div>
    <div class="btns-wrap">
      <div class="btns" @click="backHome">返回首页</div>
      <div class="btns" @click="backOder">查看订单</div>
    </div>
  </div>
  <div :class="{'mask2': true, 'mask2Show': maskshow===true}" @click="closeMask">
    <img src="../../images/share.png"/>
  </div>
</template>
<script>
import { reactive, toRefs, computed, onMounted, getCurrentInstance } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import wx from 'weixin-js-sdk'
import qs from 'qs'
import { post, ajaxParam, config } from '../../units/request.js'
export default {
  name: 'PaySuccess',
  setup () {
    const router = useRouter()
    const store = useStore()
    const { cartList, originAddress } = toRefs(store.state)
    const data = reactive({ maskshow: false, abcPayDiscount: 0, index: 0, allAmount: 0, isGroup: '1' })
    const { proxy } = getCurrentInstance()
    onMounted(() => {
      data.index = proxy.$route.params.index
      data.allAmount = proxy.$route.params.allAmount
      data.isGroup = proxy.$route.params.isGroup
    })
    const backHome = () => {
      router.push({
        path: '/'
      })
    }
    const backOder = () => {
      if (data.isGroup === '1') {
        router.push({
          path: '/mygroup'
        })
      } else {
        router.push({
          path: '/orderlist'
        })
      }
    }
    const share = async () => {
      data.maskshow = true
      const url = window.location.href.split('#')[0]
      const param = {
        url: url,
        appId: config.appId,
        appSecret: config.appSecret
      }
      ajaxParam(param)
      const res = await post('v2/user/getWxShareSignature.json', qs.stringify(param))
      if (wx) {
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: config.appId, // 必填，公众号的唯一标识
          timestamp: res.data.timestamp + '', // 必填，生成签名的时间戳
          nonceStr: res.data.nonceStr + '', // 必填，生成签名的随机串
          signature: res.data.signature, // 必填，签名，见附录1
          jsApiList: ['onMenuShareAppMessage'] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
        })
        wx.ready(() => {
          wx.onMenuShareAppMessage({
            title: cartList.value.goodsName,
            desc: cartList.value.goodsName,
            link: encodeURI(url + '#/detail?id=' + cartList.value.groupId + '&originatorId=' + localStorage.userId),
            imgUrl: cartList.value.photoUrl ? cartList.value.photoUrl : '../../images/login.png',
            type: '',
            success: (res) => {
              data.maskshow = false
            },
            cancel: (res) => {
              data.maskshow = false
            }
          })
        })
        wx.error(function (res) {
        })
      }
      /* router.push({
        path: '/detail',
        query: {
          id: cartList.value.groupId,
          originatorId: localStorage.userId
        }
      }) */
    }
    const closeMask = () => {
      data.maskshow = false
    }
    const getshopPrice = async () => {
      const param = {
        shopIds: config.shopId,
        customerUserId: localStorage.userId || ''
      }
      ajaxParam(param)
      const res = await post('v1/userShop/getShopsPrice.json', qs.stringify(param))
      if (res.data.code === '200') {
        data.abcPayDiscount = res.data.list[0].abcPayDiscount
      }
    }
    getshopPrice()
    const finalPrice = computed(() => {
      const rPrice = data.allAmount * data.abcPayDiscount / 100
      return rPrice.toFixed(2)
    })
    const { maskshow, abcPayDiscount, index, allAmount } = toRefs(data)
    return {
      allAmount, index, cartList, maskshow, closeMask, backHome, backOder, share, originAddress, abcPayDiscount, finalPrice
    }
  }
}
</script>
<style scoped lang="scss">
.container{padding: .2rem;}
.success-wrap{padding: .2rem; background:#fff; border-radius: .1rem; font-size: .16rem;}
.sucess-icon{width: 0.64rem; height:0.64rem; margin: 0.15rem auto 0}
.sucess-icon img{width: 0.64rem; height:0.64rem;}
.success-txt{display:block; margin: .2rem 0; font-size: .2rem; text-align: center; font-weight: bold;}
.success-cell{display: flex; justify-content: space-between; height: .5rem; line-height: .5rem; color:#666}
.btns-wrap{display: flex; justify-content: space-between; margin: .4rem .15rem 0}
.btns{width: 1.1rem; height: .35rem; background:#2ac560; border-radius: .05rem; text-align: center; font-size: .16rem; line-height: .35rem; color:#fff}
.btns:nth-of-type(1){background:#fff; color:#2ac560}
.private-wrap{display: flex; justify-content: space-between; align-items: center;}
.private_domain{background-color: red; font-size: .12rem; color: white;  padding: .06rem;}
.share-btn{
    color: #3b3b3b;
    width:.4rem;
    height: .28rem;
    padding-left: .18rem;
    line-height: .28rem;
    background: url(../../images/icon_share_h.png) no-repeat 0 center;
    background-size: .16rem .16rem;
}
.mask2{
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background:rgba(0,0,0,.6);
  z-index: 99;
  display: none;
  }
.mask2 img{
  width:3rem;
  position:absolute;
  left: 50%;
  top: 5%;
  margin-left: -1.5rem
}
.mask2Show{
    display: block;
}
</style>
